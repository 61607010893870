import type { BaseComponentProps } from "@q4/nimbus-ui";
import {
  ColDef,
  GetContextMenuItemsParams,
  MenuItemDef,
  RowClassParams,
  RowNode,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React from "react";
import BanklistCell from "../../../banklist/components/BanklistCell/BanklistCell";
import CustodianListCell from "../../../manager/components/CustodianListCell/CustodianListCell";
import BooleanCell from "../Cell/BooleanCell/BooleanCell";
import DateCell from "../Cell/DateCell/DateCell";
import NameCell from "../Cell/NameCell/NameCell";
import PositionCell from "../Cell/PositionCell/PositionCell";

export interface SurveillanceGridProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  gridRef?: React.RefObject<AgGridReact>;
  overlayLoadingTemplate?: string;
  overlayNoRowsTemplate?: string;
  id: string;
  data: Array<unknown>;
  DefaultColDef: ColDef;
  ColumnDefs: Array<ColDef>;
  disableLoaderText?: boolean;
  summaryDef?: SurveillanceGridSummaryDef;
  rowClassRules?: RowClassRules;
  isRowSelectable?: (node: RowNode) => boolean;
  onSelectionChanged?: (event: SelectionChangedEvent) => void;
  getContextMenuItems?: (params: GetContextMenuItemsParams) => (string | MenuItemDef)[];
  onViewportChanged?: () => void;
}

export type RowClassRules = {
  [cssClassName: string]: (params: RowClassParams) => boolean;
};

export interface SurveillanceGridSummaryDef {
  showSummary: boolean;
  totalField?: string;
  totalLabel?: string;
}

export const defaultSurveillanceGridSummaryDef: SurveillanceGridSummaryDef = {
  showSummary: false,
  totalField: "NAME",
  totalLabel: "TOTAL",
};

export enum SurveillanceGridClassName {
  Base = "surveillance-grid",
  Header = "surveillance-grid__header",
  Row = "surveillance-grid__row",
  Cell = "surveillance-grid__cell",
}

export const SurveillanceGridFrameworkComponents = {
  dateCell: DateCell,
  nameCell: NameCell,
  banklistCell: BanklistCell,
  positionCell: PositionCell,
  booleanCell: BooleanCell,
  custodianListCell: CustodianListCell,
};

export const BaseDefaultColDef = {
  resizable: true,
};
