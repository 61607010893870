import type { CacheLocation } from "@auth0/auth0-react";
import { merge } from "lodash";
import { Configuration, DeepPartial } from "./config.definition";

export enum Environment {
  Debug = "debug",
  Develop = "dev",
  Stage = "stage",
  Production = "prod",
}

// No feature flags defined yet
export enum FeatureFlag {
  ReportTextTest = "report-text-test",
  BanklistView = "banklist",
  CustodianView = "custodian",
  DTCCView = "dtcc",
  DTCCShowUploadDataButton = "dtcc-show-upload-data-button",
  DTCCStockData = "dtcc-metadata", // !We can create a properly named flag inside launch darkly for this.
  MutualFundView = "mutual-fund",
  Peers = "peers",
  MapManagerToCustodians = "map-manager-to-custodians",
  MapFundToManager = "map-fund-to-manager",
  MapMutualFundToCustodians = "map-mutual-fund-to-custodians",
  ReportCustomDateControls = "report-custom-date-controls",
  ReportPositionTypeColumn = "report-position-type-column",
  SummaryEnhancements = "summary-enhancements",
  SummaryStockPriceActivity = "summary-stock-price-activity",
  SummaryTopNetActivity = "summary-top-net-activity",
  SummaryTopDTCActivity = "summary-top-dlc-activity",
}

const environment = process.env.REACT_APP_ENV || Environment.Debug;

const defaultConfig: Configuration = {
  agGridKey:
    "Using_this_{AG_Grid}_Enterprise_key_{AG-074305}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Q4,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Q4_Desktop}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Q4_Desktop}_need_to_be_licensed___{Q4_Desktop}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{1_March_2026}____[v3]_[01]_MTc3MjMyMzIwMDAwMA==9ffa6d552bc495b864447a2d48b8e480",
  app: {
    url: process.env.REACT_APP_Q4_SURVEILLANCE_APP_URL || "http://localhost:3000",
  },
  api: {
    url: process.env.REACT_APP_Q4_SURVEILLANCE_API_URL || "http://localhost:5000/dev/api",
  },
  auth0: {
    clientId: process.env.REACT_APP_Q4_SURVEILLANCE_AUTH0_CLIENT_ID || "SUhcXIzvQYHp5zk0MEUaoSCXNC8g2Etd",
    domain: process.env.REACT_APP_Q4_SURVEILLANCE_AUTH0_DOMAIN || "auth.dev.q4inc.com",
    audience: process.env.REACT_APP_Q4_SURVEILLANCE_AUTH0_AUDIENCE || "q4-surveillance-dev.api",
    cacheLocation: "localstorage" as CacheLocation,
    storageKey: "authToken",
  },
  clientTracking: {
    apiKey: "d46224ab-a6ba-435a-49e4-b091767ee092",
  },
  env: process.env.REACT_APP_ENV || "debug",
  errorEmail: "support@q4inc.com",
  errorLog: {
    apiKey: "ab235184a4ec79a97e4a82aed5cc79de",
  },
  LDConfig: {
    clientSideID: "61ae57389c486211499ecaf0",
    options: {
      /**
       * Whether the client should make a request to LaunchDarkly for A/B testing goals.
       *
       * This is true by default, meaning that this request will be made on every page load.
       * Set it to false if you are not using A/B testing and want to skip the request.
       */
      fetchGoals: false,
    },
    reactOptions: {
      /**
       * Whether the React SDK should transform flag keys into camel-cased format. Using camel-cased flag keys allow for
       * easier use as prop values, however, these keys won't directly match the flag keys as known to LaunchDarkly.
       * Consequently, flag key collisions may be possible and the Code References feature will not function properly.
       */
      useCamelCaseFlagKeys: false,
    },
  },
  version: "2021-07-01",
  pollingTimeout: process.env.REACT_APP_Q4_SURVEILLANCE_POLLING_TIMEOUT
    ? parseInt(process.env.REACT_APP_Q4_SURVEILLANCE_POLLING_TIMEOUT)
    : 1200000,
  pollingInterval: process.env.REACT_APP_Q4_SURVEILLANCE_POLLING_INTERVAL
    ? parseInt(process.env.REACT_APP_Q4_SURVEILLANCE_POLLING_INTERVAL)
    : 20000,
  testWaitOptions: { timeout: 10000 },
};

// Using new perEnvironment Config pattern from Platform, should switch more vars over to this one
const perEnvironmentConfig: { [key: string]: DeepPartial<Configuration> } = {
  [Environment.Debug]: {
    LDConfig: {
      clientSideID: "61ae57389c486211499ecaf0",
    },
  },
  [Environment.Develop]: {
    LDConfig: {
      clientSideID: "61ae57389c486211499ecaf0",
    },
  },
  [Environment.Stage]: {
    LDConfig: {
      clientSideID: "61ae570f9c486211499ecae5",
    },
  },
  [Environment.Production]: {
    LDConfig: {
      clientSideID: "61ae570f9c486211499ecae6",
    },
  },
};
function mergeConfigs<T>(baseConfig: T, partialConfig: DeepPartial<T>): T {
  return merge(baseConfig, partialConfig) as T;
}

const config: Configuration = mergeConfigs(defaultConfig, perEnvironmentConfig[environment]);
export default config;
