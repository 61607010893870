import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.scss";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { renderToString } from "react-dom/server";
import { useDebounce } from "../../../../hooks/useDebounce/useDebounce.hook";
import { Size } from "../../../../hooks/useWindowResize";
import { useWindowSize } from "../../../../hooks/useWindowResize/useWindowResize.hook";
import DevLoader from "../devLoader/DevLoader";
import {
  SurveillanceGridClassName,
  SurveillanceGridProps,
  BaseDefaultColDef,
  SurveillanceGridFrameworkComponents,
  defaultSurveillanceGridSummaryDef,
} from "./SurveillanceGrid.definition";
import "./SurveillanceGrid.scss";

const SurveillanceGrid: React.FC<SurveillanceGridProps> = (props): JSX.Element => {
  const {
    id,
    className = "",
    data,
    gridRef,
    ColumnDefs,
    DefaultColDef,
    rowClassRules,
    overlayLoadingTemplate,
    overlayNoRowsTemplate,
    disableLoaderText,
    summaryDef = defaultSurveillanceGridSummaryDef,
    isRowSelectable = (rowNode) => rowNode.data["SOURCE"] === "Custom",
    getContextMenuItems,
    onSelectionChanged,
    onViewportChanged,
  } = props;

  const FinalDefaultColDef = { ...BaseDefaultColDef, ...DefaultColDef };

  const [gridDimensionsRequireUpdate, setGridDimensionsRequireUpdate] = useState<boolean>(false);
  const size: Size = useWindowSize();
  const debouncedSize = useDebounce(size.width, 250);

  useEffect(() => {
    if (debouncedSize) {
      setGridDimensionsRequireUpdate(true);
    }
  }, [debouncedSize, setGridDimensionsRequireUpdate]);

  const getOffset = (el: HTMLElement): Record<string, number> => {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  };

  useEffect(() => {
    if (gridDimensionsRequireUpdate) {
      const element = document.getElementById(id);
      const columnApi = gridRef.current.columnApi;
      // * Width
      if (columnApi) {
        // * We've enabled the tooltip headers with the header name to mitigate this header truncate.
        columnApi.autoSizeAllColumns(true); // * Need to auto-size all columns before grabbing the widths

        const elementToResizeWidth = element.querySelector<HTMLElement>(".ag-center-cols-container").offsetWidth;
        const parentContainerWidth = element.offsetWidth;
        // * If the element to resize is smaller than the parent container then size to fit, otherwise auto size
        if (elementToResizeWidth < parentContainerWidth) {
          columnApi.sizeColumnsToFit(parentContainerWidth);
        }
      }
      // * Height
      const agRootWrappers = element.querySelectorAll<Element>(".ag-root-wrapper") as NodeListOf<HTMLElement>;
      if (agRootWrappers && agRootWrappers.length > 0) {
        const offsetHeight = getOffset(element).top;
        agRootWrappers.forEach((agRootWrapper: HTMLElement) => {
          agRootWrapper.style.height = `${window.innerHeight - offsetHeight}px`;
        });
      }
      // * Reset
      setGridDimensionsRequireUpdate(false);
    }
  }, [gridDimensionsRequireUpdate, gridRef, id, setGridDimensionsRequireUpdate]);

  const gridOptions = {
    // PERFORMANCE
    animateRows: false,
    debounceVerticalScrollbar: true,

    // TEMPLATES
    overlayLoadingTemplate:
      typeof overlayLoadingTemplate === "string"
        ? overlayLoadingTemplate
        : renderToString(<DevLoader disableText={disableLoaderText} />),
    overlayNoRowsTemplate:
      typeof overlayNoRowsTemplate === "string"
        ? overlayNoRowsTemplate
        : renderToString(<DevLoader disableText={disableLoaderText} />),

    // CONTEXT MENU
    suppressContextMenu: !getContextMenuItems,

    // ROW
    rowClass: SurveillanceGridClassName.Row,
    rowHeight: 24,

    // CALLBACKS
    onFirstDataRendered: () => setGridDimensionsRequireUpdate(true),
    onModelUpdated: () => setGridDimensionsRequireUpdate(true),
    // Push this call to the back of the call stack using setTimeout
    onRowDataChanged: () => setTimeout(() => setGridDimensionsRequireUpdate(true)),
    ...(onSelectionChanged && { onSelectionChanged: onSelectionChanged }),
  };

  const getRowStyle = useCallback((params) => {
    if (params.node.rowPinned) {
      return { "font-weight": "bold" };
    }
  }, []);

  const summarizeRowData = useMemo(() => {
    if (!summaryDef?.showSummary || !data) return [];

    const totalRow: Record<string, unknown> = {};

    ColumnDefs.forEach((col) => {
      totalRow[col.field] = null;
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const columnData = data.map((x: Record<string, any>) => x[col.field]);
      if (col.type === "numericColumn") {
        totalRow[col.field] = (columnData as number[]).reduce((sum, current) => sum + current, 0);
      }
    });

    totalRow[summaryDef.totalField] = summaryDef.totalLabel;

    return [totalRow];
  }, [data, ColumnDefs, summaryDef]);

  return (
    <div id={id} data-testid={id} className={`${SurveillanceGridClassName.Base} ag-theme-alpine ${className}`}>
      <AgGridReact
        ref={gridRef}
        frameworkComponents={SurveillanceGridFrameworkComponents}
        defaultColDef={FinalDefaultColDef}
        columnDefs={ColumnDefs}
        gridOptions={gridOptions}
        suppressRowClickSelection={true}
        rowSelection={"multiple"}
        isRowSelectable={isRowSelectable}
        allowContextMenuWithControlKey={true}
        {...(getContextMenuItems && { getContextMenuItems: getContextMenuItems })}
        enableCellTextSelection={true}
        rowData={data}
        getRowStyle={getRowStyle}
        onViewportChanged={onViewportChanged}
        pinnedBottomRowData={summarizeRowData}
        rowClassRules={rowClassRules}
      ></AgGridReact>
    </div>
  );
};

export default SurveillanceGrid;
