/** @jsxRuntime classic */
import "./polyfills";
import "./styles/index.scss";
import "@q4/nimbus-ui/dist/_styles.css";
import "@q4/nimbus-ui/dist/_mixins.scss";
import "@q4/nimbus-ui/dist/_variables.scss";
import { Auth0Provider } from "@auth0/auth0-react";
import loadable from "@loadable/component";
import { ErrorLogProvider, LoadingScreen } from "@q4/nimbus-ui";
import { LicenseManager } from "ag-grid-enterprise";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import config, { Environment } from "./config";
import {
  LSAuthenticatedProvider,
  NavigationProvider,
  BanklistProvider,
  GlobalMessageProvider,
  ReportProvider,
  TickerProvider,
  FilingProvider,
  HomeProvider,
  DTCCProvider,
  RefetchProvider,
  ManagerProvider,
  MutualFundProvider,
  CustodianProvider,
  FundProvider,
} from "./contexts";
import * as serviceWorker from "./serviceWorker";
import Fallback from "./views/root/components/fallback/fallback.component";
import Root from "./views/root/root.view";

LicenseManager.setLicenseKey(config.agGridKey);

const LDProvider = loadable(() => asyncWithLDProvider(config.LDConfig));
/*
 * Ticker provider has to wrap the other view providers,
 * because some of the other fetch calls are dependent on a default ticker being present
 */
render(
  <LDProvider fallback={<LoadingScreen />}>
    <Auth0Provider
      {...config.auth0}
      authorizationParams={{ redirect_uri: window.location.origin, audience: config.auth0.audience }}
    >
      <ErrorLogProvider
        bugsnagConfig={{
          apiKey: config.errorLog.apiKey,
          collectUserIp: false,
          enabledReleaseStages: [Environment.Develop, Environment.Production],
          releaseStage: config.env as Environment,
          appVersion: config.version,
        }}
        FallbackComponent={Fallback}
      >
        <GlobalMessageProvider>
          <LSAuthenticatedProvider>
            <Router>
              <NavigationProvider>
                <RefetchProvider>
                  <TickerProvider>
                    <HomeProvider>
                      <DTCCProvider>
                        <ReportProvider>
                          <FilingProvider>
                            <BanklistProvider>
                              <MutualFundProvider>
                                <ManagerProvider>
                                  <CustodianProvider>
                                    <FundProvider>
                                      <Root />
                                    </FundProvider>
                                  </CustodianProvider>
                                </ManagerProvider>
                              </MutualFundProvider>
                            </BanklistProvider>
                          </FilingProvider>
                        </ReportProvider>
                      </DTCCProvider>
                    </HomeProvider>
                  </TickerProvider>
                </RefetchProvider>
              </NavigationProvider>
            </Router>
          </LSAuthenticatedProvider>
        </GlobalMessageProvider>
      </ErrorLogProvider>
    </Auth0Provider>
  </LDProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
