import type { BaseComponentWithChildrenProps, ErrorLogContextState } from "@q4/nimbus-ui";
import { RouteProps } from "react-router-dom";

export interface FundProps extends BaseComponentWithChildrenProps {
  errorLog?: ErrorLogContextState;
  routeProps?: RouteProps;
}

export enum FundClassName {
  Base = "fund",
  Grid = "fund__grid",
  Modal = "fund__modal",
  NoDataMessage = "fund__no-data-message",
  Transactions = "fund__transactions",
  TransactionsToolbarContent = "fund__transactions-toolbar-content",
  Funds = "fund__funds",
  FundsToolbarContent = "fund__funds-toolbar-content",
}
