import "./ProfileMenu.component.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { Ghostable, PopoverMenu, PopoverMenuProps } from "@q4/nimbus-ui";
import React, { useContext, useMemo, useState } from "react";
import config from "../../../../config";
import { LSAuthenticatedContext } from "../../../../contexts";
import ContactButton from "../Button/ContactButton/ContactButton";
import { ProfileMenuClassName, ProfileMenuIdModel, ProfileMenuProps } from "./ProfileMenu.definition";

const ProfileButton = (props: ProfileMenuProps): JSX.Element => {
  const { id } = props;
  const { logout } = useAuth0();
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
  const handleOpenProfileMenu = () => setProfileMenuOpen(true);
  const handleCloseProfileMenu = () => setProfileMenuOpen(false);
  const { logoutCleanup } = useContext(LSAuthenticatedContext);
  const handleSignOutClick = async () => {
    localStorage.removeItem(config.auth0.storageKey);
    logoutCleanup();
    try {
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  const idModel = useMemo(() => new ProfileMenuIdModel(id), [id]);
  const profileMenuProps: PopoverMenuProps = {
    anchorTargetElementId: idModel.profile.id,
    visible: profileMenuOpen,
    options: [{ id: idModel.logOut?.id, label: "Log Out", onClick: handleSignOutClick }],
    onCloseRequest: handleCloseProfileMenu,
    masked: true,
  };
  return (
    <div className={ProfileMenuClassName.Base}>
      <Ghostable ghosted={false}>
        <ContactButton id={idModel.profile.id} disabled={false} onClick={handleOpenProfileMenu} />
      </Ghostable>
      <PopoverMenu className={ProfileMenuClassName.Popover} {...profileMenuProps} />
    </div>
  );
};

export default ProfileButton;
