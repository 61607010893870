import type { BaseComponentWithChildrenProps, ErrorLogContextState } from "@q4/nimbus-ui";
import { RouteProps } from "react-router-dom";

export interface CustodianProps extends BaseComponentWithChildrenProps {
  errorLog?: ErrorLogContextState;
  location?: Record<string, unknown>;
  routeProps?: RouteProps;
}

export enum CustodianClassName {
  Base = "custodian",
  Grid = "custodian__grid",
  Modal = "custodian__modal",
  HalfHeight = "half-height",
}
