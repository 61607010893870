import type { BaseComponentWithChildrenProps, MessageProps } from "@q4/nimbus-ui";

export type ConfirmationModalProviderProps = BaseComponentWithChildrenProps;
export type ConfirmationModalProviderState = Pick<MessageProps, "visible" | "title" | "message" | "primaryActionProps">;

export class ConfirmationActionResponse {
  confirmed: boolean;

  constructor(confirmed = true) {
    this.confirmed = confirmed;
  }
}

export interface GlobalMessageContextState extends ConfirmationModalProviderState {
  /**
   * Opens the Confirmation Message, only allows users to confirm or deny an action.
   * The confirmation modal allows for asynchronous confirmation of an action.
   */
  open: (
    title: string,
    message: string,
    primaryActionProps: Pick<MessageProps["primaryActionProps"], "label">
  ) => Promise<ConfirmationActionResponse>;
  /**
   * Closes the Confirmation Message
   */
  close(response?: ConfirmationActionResponse): void;
  /**
   * Clears the Confirmation Message Context
   */
  clear(): void;
}
